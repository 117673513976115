import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { useHistory, Link } from 'react-router-dom';
import styled from 'styled-components';
import { MdShoppingCart } from 'react-icons/md';
import { FaSearch, FaTimesCircle } from 'react-icons/fa';
// local imports

import { GET_DISHES } from '../graphql/getDishes';
import { CREATE_ORDER } from '../graphql/createOrder';
import { UPDATE_WEEKLY_TOTAL } from '../graphql/updateWeeklyTotal';

export default function Dishes() {
    const history = useHistory();
    const [id, setId] = useState('');
    const [id0, setId0] = useState('');
    const [id1, setId1] = useState('');
    const [id2, setId2] = useState('');
    const [id3, setId3] = useState('');
    const [id4, setId4] = useState('');
    const [id5, setId5] = useState('');
    const [id6, setId6] = useState('');
    const [id7, setId7] = useState('');
    const [id8, setId8] = useState('');
    const [id9, setId9] = useState('');
    const [id10, setId10] = useState('');
    const [qty0, setQty0] = useState(0);
    const [qty1, setQty1] = useState(0);
    const [qty2, setQty2] = useState(0);
    const [qty3, setQty3] = useState(0);
    const [qty4, setQty4] = useState(0);
    const [qty5, setQty5] = useState(0);
    const [qty6, setQty6] = useState(0);
    const [qty7, setQty7] = useState(0);
    const [qty8, setQty8] = useState(0);
    const [qty9, setQty9] = useState(0);
    const [qty10, setQty10] = useState(0);
    const [date, setDate] = useState('');
    const [dish0, setDish0] = useState();
    const [dish1, setDish1] = useState();
    const [dish2, setDish2] = useState();
    const [dish3, setDish3] = useState();
    const [dish4, setDish4] = useState();
    const [dish5, setDish5] = useState();
    const [dish6, setDish6] = useState();
    const [dish7, setDish7] = useState();
    const [dish8, setDish8] = useState();
    const [dish9, setDish9] = useState();
    const [dish10, setDish10] = useState();
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [comment, setComment] = useState('');
    const [totalItems, setTotalItems] = useState('');
    const [checkoutTotal, setCheckoutTotal] = useState(0);
    const [weeklyTotal, setWeeklyTotal] = useState(0);
    const [cap0, setCap0] = useState(0);
    const [cap1, setCap1] = useState(0);
    const [cap2, setCap2] = useState(0);
    const [cap3, setCap3] = useState(0);
    const [cap4, setCap4] = useState(0);
    const [cap5, setCap5] = useState(0);
    const [cap6, setCap6] = useState(0);
    const [cap7, setCap7] = useState(0);
    const [cap8, setCap8] = useState(0);
    const [cap9, setCap9] = useState(0);
    const [cap10, setCap10] = useState(0);
    const [wT0, setWT0] = useState(0);
    const [wT1, setWT1] = useState(0);
    const [wT2, setWT2] = useState(0);
    const [wT3, setWT3] = useState(0);
    const [wT4, setWT4] = useState(0);
    const [wT5, setWT5] = useState(0);
    const [wT6, setWT6] = useState(0);
    const [wT7, setWT7] = useState(0);
    const [wT8, setWT8] = useState(0);
    const [wT9, setWT9] = useState(0);
    const [wT10, setWT10] = useState(0);
    const [pickupIsCool, setPickupIsCool] = useState();

    // QUERIES WHICH DISHES ARE AVAILABLE HERE
    const { data, error, loading } = useQuery(GET_DISHES, {
        variables: { isAvailable: true },
        refetchQueries: ['GetDishes'],
    });

    // SETS ORDER DATE AND ADDS UP GENERIC TOTAL ITEMS FOR USER
    useEffect(() => {
        setDate(new Date());
        function getTotal() {
            const newTotal =
                qty0 + qty1 + qty2 + qty3 + qty4 + qty5 + qty6 + qty7 + qty8 + qty9 + qty10;
            setTotalItems(newTotal);
        }
        getTotal();
    }, [qty0, qty1, qty10, qty2, qty3, qty4, qty5, qty6, qty7, qty8, qty9]);

    // CHECKS TO SEE IF DISH EXISTS, AND IF SO,
    // SETS THE ORDER'S DISHNAME AND QUANTITY
    useEffect(() => {
        if (data) {
            if (data.dishes[0]) {
                setDish0(data.dishes[0].dishname);
                setQty0(0);
                setCap0(data.dishes[0].capacity);
                setWT0(data.dishes[0].weeklyTotal);
                setId0(data.dishes[0].id);
            }
            if (data.dishes[1]) {
                setDish1(data.dishes[1].dishname);
                setQty1(0);
                setCap1(data.dishes[1].capacity);
                setWT1(data.dishes[1].weeklyTotal);
                setId1(data.dishes[1].id);
            }
            if (data.dishes[2]) {
                setDish2(data.dishes[2].dishname);
                setQty2(0);
                setCap2(data.dishes[2].capacity);
                setWT2(data.dishes[2].weeklyTotal);
                setId2(data.dishes[2].id);
            }
            if (data.dishes[3]) {
                setDish3(data.dishes[3].dishname);
                setQty3(0);
                setCap3(data.dishes[3].capacity);
                setWT3(data.dishes[3].weeklyTotal);
                setId3(data.dishes[3].id);
            }
            if (data.dishes[4]) {
                setDish4(data.dishes[4].dishname);
                setQty4(0);
                setCap4(data.dishes[4].capacity);
                setWT4(data.dishes[4].weeklyTotal);
                setId4(data.dishes[4].id);
            }
            if (data.dishes[5]) {
                setDish5(data.dishes[5].dishname);
                setQty5(0);
                setCap5(data.dishes[5].capacity);
                setWT5(data.dishes[5].weeklyTotal);
                setId5(data.dishes[5].id);
            }
            if (data.dishes[6]) {
                setDish6(data.dishes[6].dishname);
                setQty6(0);
                setCap6(data.dishes[6].capacity);
                setWT6(data.dishes[6].weeklyTotal);
                setId6(data.dishes[6].id);
            }
            if (data.dishes[7]) {
                setDish7(data.dishes[7].dishname);
                setQty7(0);
                setCap7(data.dishes[7].capacity);
                setWT7(data.dishes[7].weeklyTotal);
                setId7(data.dishes[7].id);
            }
            if (data.dishes[8]) {
                setDish8(data.dishes[8].dishname);
                setQty8(0);
                setCap8(data.dishes[8].capacity);
                setWT8(data.dishes[8].weeklyTotal);
                setId8(data.dishes[8].id);
            }
            if (data.dishes[9]) {
                setDish9(data.dishes[9].dishname);
                setQty9(0);
                setCap9(data.dishes[9].capacity);
                setWT9(data.dishes[9].weeklyTotal);
                setId9(data.dishes[9].id);
            }
            if (data.dishes[10]) {
                setDish10(data.dishes[10].dishname);
                setQty10(0);
                setCap10(data.dishes[10].capacity);
                setWT10(data.dishes[10].weeklyTotal);
                setId10(data.dishes[10].id);
            }
        }
    }, [data]);

    useEffect(() => {
        let t0,
            t1,
            t2,
            t3,
            t4,
            t5,
            t6,
            t7,
            t8,
            t9,
            t10 = 0;
        if (data) {
            t0 = qty0 ? (t0 = qty0 * data.dishes[0].price) : (t0 = 0);
            t1 = qty1 ? (t1 = qty1 * data.dishes[1].price) : (t1 = 0);
            t2 = qty2 ? (t2 = qty2 * data.dishes[2].price) : (t2 = 0);
            t3 = qty3 ? (t3 = qty3 * data.dishes[3].price) : (t3 = 0);
            t4 = qty4 ? (t4 = qty4 * data.dishes[4].price) : (t4 = 0);
            t5 = qty5 ? (t5 = qty5 * data.dishes[5].price) : (t5 = 0);
            t6 = qty6 ? (t6 = qty6 * data.dishes[6].price) : (t6 = 0);
            t7 = qty7 ? (t7 = qty7 * data.dishes[7].price) : (t7 = 0);
            t8 = qty8 ? (t8 = qty8 * data.dishes[8].price) : (t8 = 0);
            t9 = qty9 ? (t9 = qty9 * data.dishes[9].price) : (t9 = 0);
            t10 = qty10 ? (t10 = qty10 * data.dishes[10].price) : (t10 = 0);

            setCheckoutTotal(t0 + t1 + t2 + t3 + t4 + t5 + t6 + t7 + t8 + t9 + t10);
        }
    }, [data, qty0, qty1, qty10, qty2, qty3, qty4, qty5, qty6, qty7, qty8, qty9, wT0]);

    /* ***************************** */
    /* ******** MUTATIONS  ********* */
    /* ***************************** */
    const [createOrder, { orderData }] = useMutation(CREATE_ORDER, {
        variables: {
            email,
            phone,
            name,
            comment,
            totalItems,
            checkoutTotal,
            date,
            qty0,
            qty1,
            qty2,
            qty3,
            qty4,
            qty5,
            qty6,
            qty7,
            qty8,
            qty9,
            qty10,
            dish0,
            dish1,
            dish2,
            dish3,
            dish4,
            dish5,
            dish6,
            dish7,
            dish8,
            dish9,
            dish10,
            hasPaid: false,
        },
        refetchQueries: ['GetCategoryEntries'],
    });

    const [updateWeeklyTotal] = useMutation(UPDATE_WEEKLY_TOTAL, {
        variables: {
            id,
            weeklyTotal,
        },
        refetchQueries: ['dishes'],
    });

    const handleSubmit = (e) => {
        e.preventDefault();

        // dataId is not getting set in time sometimes,
        // so we go to createEntry without that variable

        // SECOND MUTATION FAILING?
        // DUDE, YOU GOTTA RETURN YOUR PROMISE,
        // EVEN IF YOU ONLY USE IT AS A CONSOLE LOG.

        createOrder()
            .then((result) => {
                console.log(`result: ${result}`, orderData);
            })
            .then(() => {
                if (qty0) {
                    setId(id0);
                    setWeeklyTotal(wT0 + qty0);
                    updateWeeklyTotal();
                }
            })
            .then(() => {
                if (qty1) {
                    setId(id1);
                    setWeeklyTotal(wT1 + qty1);
                    updateWeeklyTotal();
                }
            })
            .then(() => {
                if (qty2) {
                    setId(id2);
                    setWeeklyTotal(wT2 + qty2);
                    updateWeeklyTotal();
                }
            })
            .then(() => {
                if (qty3) {
                    setId(id3);
                    setWeeklyTotal(wT3 + qty3);
                    updateWeeklyTotal();
                }
            })
            .then(() => {
                if (qty4) {
                    setId(id4);
                    setWeeklyTotal(wT4 + qty4);
                    updateWeeklyTotal();
                }
            })
            .then(() => {
                if (qty5) {
                    setId(id5);
                    setWeeklyTotal(wT5 + qty5);
                    updateWeeklyTotal();
                }
            })
            .then(() => {
                if (qty6) {
                    setId(id6);
                    setWeeklyTotal(wT6 + qty6);
                    updateWeeklyTotal();
                }
            })
            .then(() => {
                if (qty7) {
                    setId(id7);
                    setWeeklyTotal(wT7 + qty7);
                    updateWeeklyTotal();
                }
            })
            .then(() => {
                if (qty8) {
                    setId(id8);
                    setWeeklyTotal(wT8 + qty8);
                    updateWeeklyTotal();
                }
            })
            .then(() => {
                if (qty9) {
                    setId(id9);
                    setWeeklyTotal(wT9 + qty9);
                    updateWeeklyTotal();
                }
            })
            .then(() => {
                if (qty10) {
                    setId(id10);
                    setWeeklyTotal(wT10 + qty10);
                    updateWeeklyTotal();
                }
            })
            .then(() => {
                setTimeout(() => {
                    history.push({
                        pathname: '/thanks',
                        state: {
                            checkoutTotal,
                        },
                    });
                    window.location.reload(false);
                }, 200);
            });
    };

    function imageZoom(i) {
        const getModal = document.getElementById('modal' + i);
        getModal.style.display = 'block';
        const scroller = document.getElementById('root');
        scroller.style.overflow = 'hidden';
    }

    function closeModal(i) {
        const getModal = document.getElementById('modal' + i);
        getModal.style.display = 'none';
        const scroller = document.getElementById('root');
        scroller.style.overflow = 'auto';
    }

    if (loading) return <h2>Loading ....</h2>;
    if (error) return `Error! ${error}`;

    return (
        <>
            <TopBar>
                <ContentWrapper>
                    <Title>Em's meals to go</Title>

                    <Cart>
                        <MdShoppingCart />
                    </Cart>
                    {totalItems !== 0 ? <TotalDot>{totalItems}</TotalDot> : <></>}
                </ContentWrapper>
            </TopBar>
            <ContentWrapper>
                <Link to="/mealplan-form">
                    <MealPlanPromo src="mealplans.png" alt="meal plans" />
                </Link>
                <Subhead top>
                    Step 1: <span>Choose dishes</span>
                </Subhead>

                {data.dishes[0] ? (
                    <>
                        {wT0 < cap0 ? (
                            <Dish>
                                <Modal id="modal0">
                                    <div>
                                        <img
                                            src={data.dishes[0].photoUrl}
                                            alt={data.dishes[0].dishname}
                                        />
                                        <button onClick={() => closeModal('0')}>
                                            <FaTimesCircle />
                                        </button>
                                    </div>
                                </Modal>
                                <DishPhoto onClick={() => imageZoom('0')}>
                                    <img
                                        src={data.dishes[0].photoUrl}
                                        alt={data.dishes[0].dishname}
                                    />
                                    <ImageZoom>
                                        <FaSearch />
                                    </ImageZoom>
                                </DishPhoto>
                                <DishContent>
                                    <DishName title="dish0">{data.dishes[0].dishname}</DishName>
                                    <DishDescription>{data.dishes[0].description}</DishDescription>
                                    <QuantityWrapper>
                                        {' '}
                                        <Price>
                                            ${parseFloat(data.dishes[0].price).toFixed(2)}
                                        </Price>
                                        {qty0 === 0 ? (
                                            <OneButton>
                                                <Plus onClick={() => setQty0(qty0 + 1)}>+</Plus>
                                            </OneButton>
                                        ) : (
                                            <BothButtons>
                                                <Minus onClick={() => setQty0(qty0 - 1)}>-</Minus>
                                                <Quantity title="qty0">{qty0}</Quantity>
                                                <Plus onClick={() => setQty0(qty0 + 1)}>+</Plus>
                                            </BothButtons>
                                        )}
                                    </QuantityWrapper>
                                </DishContent>
                            </Dish>
                        ) : (
                            <Soldout>
                                <SoldMessage>Sold Out</SoldMessage>
                                <Dish>
                                    <DishPhoto>
                                        <img
                                            src={data.dishes[0].photoUrl}
                                            alt={data.dishes[0].dishname}
                                        />
                                        <ImageZoom>
                                            <FaSearch />
                                        </ImageZoom>
                                    </DishPhoto>
                                    <DishContent>
                                        <DishName title="dish0">{data.dishes[0].dishname}</DishName>

                                        <DishDescription>
                                            {data.dishes[0].description}
                                        </DishDescription>
                                        <QuantityWrapper>
                                            {' '}
                                            <Price>
                                                ${parseFloat(data.dishes[0].price).toFixed(2)}
                                            </Price>
                                            {qty0 === 0 ? (
                                                <OneButton>
                                                    <Plus>+</Plus>
                                                </OneButton>
                                            ) : (
                                                <BothButtons>
                                                    <Minus>-</Minus>
                                                    <Quantity title="qty0">{qty0}</Quantity>
                                                    <Plus>+</Plus>
                                                </BothButtons>
                                            )}
                                        </QuantityWrapper>
                                    </DishContent>
                                </Dish>
                            </Soldout>
                        )}
                    </>
                ) : (
                    <></>
                )}
                {data.dishes[1] ? (
                    <>
                        {wT1 < cap1 ? (
                            <Dish>
                                <Modal id="modal1">
                                    <div>
                                        <img
                                            src={data.dishes[1].photoUrl}
                                            alt={data.dishes[1].dishname}
                                        />
                                        <button onClick={() => closeModal('1')}>
                                            <FaTimesCircle />
                                        </button>
                                    </div>
                                </Modal>
                                <DishPhoto onClick={() => imageZoom('1')}>
                                    <img
                                        src={data.dishes[1].photoUrl}
                                        alt={data.dishes[1].dishname}
                                    />
                                    <ImageZoom>
                                        <FaSearch />
                                    </ImageZoom>
                                </DishPhoto>
                                <DishContent>
                                    <DishName title="dish1">{data.dishes[1].dishname}</DishName>

                                    <DishDescription>{data.dishes[1].description}</DishDescription>
                                    <QuantityWrapper>
                                        {' '}
                                        <Price>
                                            ${parseFloat(data.dishes[1].price).toFixed(2)}
                                        </Price>
                                        {qty1 === 0 ? (
                                            <OneButton>
                                                <Plus onClick={() => setQty1(qty1 + 1)}>+</Plus>
                                            </OneButton>
                                        ) : (
                                            <BothButtons>
                                                <Minus onClick={() => setQty1(qty1 - 1)}>-</Minus>
                                                <Quantity title="qty1">{qty1}</Quantity>
                                                <Plus onClick={() => setQty1(qty1 + 1)}>+</Plus>
                                            </BothButtons>
                                        )}
                                    </QuantityWrapper>
                                </DishContent>
                            </Dish>
                        ) : (
                            <Soldout>
                                <SoldMessage>Sold Out</SoldMessage>
                                <Dish>
                                    <DishPhoto>
                                        <img
                                            src={data.dishes[1].photoUrl}
                                            alt={data.dishes[1].dishname}
                                        />
                                        <ImageZoom>
                                            <FaSearch />
                                        </ImageZoom>
                                    </DishPhoto>
                                    <DishContent>
                                        <DishName title="dish1">{data.dishes[1].dishname}</DishName>

                                        <DishDescription>
                                            {data.dishes[1].description}
                                        </DishDescription>
                                        <QuantityWrapper>
                                            {' '}
                                            <Price>
                                                ${parseFloat(data.dishes[1].price).toFixed(2)}
                                            </Price>
                                            {qty1 === 0 ? (
                                                <OneButton>
                                                    <Plus>+</Plus>
                                                </OneButton>
                                            ) : (
                                                <BothButtons>
                                                    <Minus>-</Minus>
                                                    <Quantity title="qty1">{qty1}</Quantity>
                                                    <Plus>+</Plus>
                                                </BothButtons>
                                            )}
                                        </QuantityWrapper>
                                    </DishContent>
                                </Dish>
                            </Soldout>
                        )}
                    </>
                ) : (
                    <></>
                )}

                {data.dishes[2] ? (
                    <>
                        {wT2 < cap2 ? (
                            <Dish>
                                <Modal id="modal2">
                                    <div>
                                        <img
                                            src={data.dishes[2].photoUrl}
                                            alt={data.dishes[2].dishname}
                                        />
                                        <button onClick={() => closeModal('2')}>
                                            <FaTimesCircle />
                                        </button>
                                    </div>
                                </Modal>
                                <DishPhoto onClick={() => imageZoom('2')}>
                                    <img
                                        src={data.dishes[2].photoUrl}
                                        alt={data.dishes[2].dishname}
                                    />
                                    <ImageZoom>
                                        <FaSearch />
                                    </ImageZoom>
                                </DishPhoto>
                                <DishContent>
                                    <DishName title="dish2">{data.dishes[2].dishname}</DishName>

                                    <DishDescription>{data.dishes[2].description}</DishDescription>
                                    <QuantityWrapper>
                                        {' '}
                                        <Price>
                                            ${parseFloat(data.dishes[2].price).toFixed(2)}
                                        </Price>
                                        {qty2 === 0 ? (
                                            <OneButton>
                                                <Plus onClick={() => setQty2(qty2 + 1)}>+</Plus>
                                            </OneButton>
                                        ) : (
                                            <BothButtons>
                                                <Minus onClick={() => setQty2(qty2 - 1)}>-</Minus>
                                                <Quantity title="qty2">{qty2}</Quantity>
                                                <Plus onClick={() => setQty2(qty2 + 1)}>+</Plus>
                                            </BothButtons>
                                        )}
                                    </QuantityWrapper>
                                </DishContent>
                            </Dish>
                        ) : (
                            <Soldout>
                                <SoldMessage>Sold Out</SoldMessage>
                                <Dish>
                                    <DishPhoto>
                                        <img
                                            src={data.dishes[2].photoUrl}
                                            alt={data.dishes[2].dishname}
                                        />
                                        <ImageZoom>
                                            <FaSearch />
                                        </ImageZoom>
                                    </DishPhoto>
                                    <DishContent>
                                        <DishName title="dish2">{data.dishes[2].dishname}</DishName>

                                        <DishDescription>
                                            {data.dishes[2].description}
                                        </DishDescription>
                                        <QuantityWrapper>
                                            {' '}
                                            <Price>
                                                ${parseFloat(data.dishes[2].price).toFixed(2)}
                                            </Price>
                                            {qty2 === 0 ? (
                                                <OneButton>
                                                    <Plus>+</Plus>
                                                </OneButton>
                                            ) : (
                                                <BothButtons>
                                                    <Minus>-</Minus>
                                                    <Quantity title="qty2">{qty2}</Quantity>
                                                    <Plus>+</Plus>
                                                </BothButtons>
                                            )}
                                        </QuantityWrapper>
                                    </DishContent>
                                </Dish>
                            </Soldout>
                        )}
                    </>
                ) : (
                    <></>
                )}

                {data.dishes[3] ? (
                    <>
                        {wT3 < cap3 ? (
                            <Dish>
                                <Modal id="modal3">
                                    <div>
                                        <img
                                            src={data.dishes[3].photoUrl}
                                            alt={data.dishes[3].dishname}
                                        />
                                        <button onClick={() => closeModal('3')}>
                                            <FaTimesCircle />
                                        </button>
                                    </div>
                                </Modal>
                                <DishPhoto onClick={() => imageZoom('3')}>
                                    <img
                                        src={data.dishes[3].photoUrl}
                                        alt={data.dishes[3].dishname}
                                    />
                                    <ImageZoom>
                                        <FaSearch />
                                    </ImageZoom>
                                </DishPhoto>
                                <DishContent>
                                    <DishName title="dish3">{data.dishes[3].dishname}</DishName>

                                    <DishDescription>{data.dishes[3].description}</DishDescription>
                                    <QuantityWrapper>
                                        {' '}
                                        <Price>
                                            ${parseFloat(data.dishes[3].price).toFixed(2)}
                                        </Price>
                                        {qty3 === 0 ? (
                                            <OneButton>
                                                <Plus onClick={() => setQty3(qty3 + 1)}>+</Plus>
                                            </OneButton>
                                        ) : (
                                            <BothButtons>
                                                <Minus onClick={() => setQty3(qty3 - 1)}>-</Minus>
                                                <Quantity title="qty3">{qty3}</Quantity>
                                                <Plus onClick={() => setQty3(qty3 + 1)}>+</Plus>
                                            </BothButtons>
                                        )}
                                    </QuantityWrapper>
                                </DishContent>
                            </Dish>
                        ) : (
                            <Soldout>
                                <SoldMessage>Sold Out</SoldMessage>
                                <Dish>
                                    <DishPhoto>
                                        <img
                                            src={data.dishes[3].photoUrl}
                                            alt={data.dishes[3].dishname}
                                        />
                                        <ImageZoom>
                                            <FaSearch />
                                        </ImageZoom>
                                    </DishPhoto>
                                    <DishContent>
                                        <DishName title="dish3">{data.dishes[3].dishname}</DishName>

                                        <DishDescription>
                                            {data.dishes[3].description}
                                        </DishDescription>
                                        <QuantityWrapper>
                                            {' '}
                                            <Price>
                                                ${parseFloat(data.dishes[3].price).toFixed(2)}
                                            </Price>
                                            {qty3 === 0 ? (
                                                <OneButton>
                                                    <Plus>+</Plus>
                                                </OneButton>
                                            ) : (
                                                <BothButtons>
                                                    <Minus>-</Minus>
                                                    <Quantity title="qty3">{qty3}</Quantity>
                                                    <Plus>+</Plus>
                                                </BothButtons>
                                            )}
                                        </QuantityWrapper>
                                    </DishContent>
                                </Dish>
                            </Soldout>
                        )}
                    </>
                ) : (
                    <></>
                )}

                {data.dishes[4] ? (
                    <>
                        {wT4 < cap4 ? (
                            <Dish>
                                <Modal id="modal4">
                                    <div>
                                        <img
                                            src={data.dishes[4].photoUrl}
                                            alt={data.dishes[4].dishname}
                                        />
                                        <button onClick={() => closeModal('4')}>
                                            <FaTimesCircle />
                                        </button>
                                    </div>
                                </Modal>
                                <DishPhoto onClick={() => imageZoom('4')}>
                                    <img
                                        src={data.dishes[4].photoUrl}
                                        alt={data.dishes[4].dishname}
                                    />
                                    <ImageZoom>
                                        <FaSearch />
                                    </ImageZoom>
                                </DishPhoto>
                                <DishContent>
                                    <DishName title="dish4">{data.dishes[4].dishname}</DishName>

                                    <DishDescription>{data.dishes[4].description}</DishDescription>
                                    <QuantityWrapper>
                                        {' '}
                                        <Price>
                                            ${parseFloat(data.dishes[4].price).toFixed(2)}
                                        </Price>
                                        {qty4 === 0 ? (
                                            <OneButton>
                                                <Plus onClick={() => setQty4(qty4 + 1)}>+</Plus>
                                            </OneButton>
                                        ) : (
                                            <BothButtons>
                                                <Minus onClick={() => setQty4(qty4 - 1)}>-</Minus>
                                                <Quantity title="qty4">{qty4}</Quantity>
                                                <Plus onClick={() => setQty4(qty4 + 1)}>+</Plus>
                                            </BothButtons>
                                        )}
                                    </QuantityWrapper>
                                </DishContent>
                            </Dish>
                        ) : (
                            <Soldout>
                                <SoldMessage>Sold Out</SoldMessage>
                                <Dish>
                                    <DishPhoto>
                                        <img
                                            src={data.dishes[4].photoUrl}
                                            alt={data.dishes[4].dishname}
                                        />
                                        <ImageZoom>
                                            <FaSearch />
                                        </ImageZoom>
                                    </DishPhoto>
                                    <DishContent>
                                        <DishName title="dish4">{data.dishes[4].dishname}</DishName>

                                        <DishDescription>
                                            {data.dishes[4].description}
                                        </DishDescription>
                                        <QuantityWrapper>
                                            {' '}
                                            <Price>
                                                ${parseFloat(data.dishes[4].price).toFixed(2)}
                                            </Price>
                                            {qty4 === 0 ? (
                                                <OneButton>
                                                    <Plus>+</Plus>
                                                </OneButton>
                                            ) : (
                                                <BothButtons>
                                                    <Minus>-</Minus>
                                                    <Quantity title="qty4">{qty4}</Quantity>
                                                    <Plus>+</Plus>
                                                </BothButtons>
                                            )}
                                        </QuantityWrapper>
                                    </DishContent>
                                </Dish>
                            </Soldout>
                        )}
                    </>
                ) : (
                    <></>
                )}

                {data.dishes[5] ? (
                    <>
                        {wT5 < cap5 ? (
                            <Dish>
                                <Modal id="modal5">
                                    <div>
                                        <img
                                            src={data.dishes[5].photoUrl}
                                            alt={data.dishes[5].dishname}
                                        />
                                        <button onClick={() => closeModal('5')}>
                                            <FaTimesCircle />
                                        </button>
                                    </div>
                                </Modal>
                                <DishPhoto onClick={() => imageZoom('5')}>
                                    <img
                                        src={data.dishes[5].photoUrl}
                                        alt={data.dishes[5].dishname}
                                    />
                                    <ImageZoom>
                                        <FaSearch />
                                    </ImageZoom>
                                </DishPhoto>
                                <DishContent>
                                    <DishName title="dish5">{data.dishes[5].dishname}</DishName>

                                    <DishDescription>{data.dishes[5].description}</DishDescription>
                                    <QuantityWrapper>
                                        {' '}
                                        <Price>
                                            ${parseFloat(data.dishes[5].price).toFixed(2)}
                                        </Price>
                                        {qty5 === 0 ? (
                                            <OneButton>
                                                <Plus onClick={() => setQty5(qty5 + 1)}>+</Plus>
                                            </OneButton>
                                        ) : (
                                            <BothButtons>
                                                <Minus onClick={() => setQty5(qty5 - 1)}>-</Minus>
                                                <Quantity title="qty5">{qty5}</Quantity>
                                                <Plus onClick={() => setQty5(qty5 + 1)}>+</Plus>
                                            </BothButtons>
                                        )}
                                    </QuantityWrapper>
                                </DishContent>
                            </Dish>
                        ) : (
                            <Soldout>
                                <SoldMessage>Sold Out</SoldMessage>
                                <Dish>
                                    <DishPhoto>
                                        <img
                                            src={data.dishes[5].photoUrl}
                                            alt={data.dishes[5].dishname}
                                        />
                                        <ImageZoom>
                                            <FaSearch />
                                        </ImageZoom>
                                    </DishPhoto>
                                    <DishContent>
                                        <DishName title="dish5">{data.dishes[5].dishname}</DishName>

                                        <DishDescription>
                                            {data.dishes[5].description}
                                        </DishDescription>
                                        <QuantityWrapper>
                                            {' '}
                                            <Price>
                                                ${parseFloat(data.dishes[5].price).toFixed(2)}
                                            </Price>
                                            {qty5 === 0 ? (
                                                <OneButton>
                                                    <Plus>+</Plus>
                                                </OneButton>
                                            ) : (
                                                <BothButtons>
                                                    <Minus>-</Minus>
                                                    <Quantity title="qty5">{qty5}</Quantity>
                                                    <Plus>+</Plus>
                                                </BothButtons>
                                            )}
                                        </QuantityWrapper>
                                    </DishContent>
                                </Dish>
                            </Soldout>
                        )}
                    </>
                ) : (
                    <></>
                )}

                {data.dishes[6] ? (
                    <>
                        {wT6 < cap6 ? (
                            <Dish>
                                <Modal id="modal6">
                                    <div>
                                        <img
                                            src={data.dishes[6].photoUrl}
                                            alt={data.dishes[6].dishname}
                                        />
                                        <button onClick={() => closeModal('6')}>
                                            <FaTimesCircle />
                                        </button>
                                    </div>
                                </Modal>
                                <DishPhoto onClick={() => imageZoom('6')}>
                                    <img
                                        src={data.dishes[6].photoUrl}
                                        alt={data.dishes[6].dishname}
                                    />
                                    <ImageZoom>
                                        <FaSearch />
                                    </ImageZoom>
                                </DishPhoto>
                                <DishContent>
                                    <DishName title="dish6">{data.dishes[6].dishname}</DishName>

                                    <DishDescription>{data.dishes[6].description}</DishDescription>
                                    <QuantityWrapper>
                                        {' '}
                                        <Price>
                                            ${parseFloat(data.dishes[6].price).toFixed(2)}
                                        </Price>
                                        {qty6 === 0 ? (
                                            <OneButton>
                                                <Plus onClick={() => setQty6(qty6 + 1)}>+</Plus>
                                            </OneButton>
                                        ) : (
                                            <BothButtons>
                                                <Minus onClick={() => setQty6(qty6 - 1)}>-</Minus>
                                                <Quantity title="qty6">{qty6}</Quantity>
                                                <Plus onClick={() => setQty6(qty6 + 1)}>+</Plus>
                                            </BothButtons>
                                        )}
                                    </QuantityWrapper>
                                </DishContent>
                            </Dish>
                        ) : (
                            <Soldout>
                                <SoldMessage>Sold Out</SoldMessage>
                                <Dish>
                                    <DishPhoto>
                                        <img
                                            src={data.dishes[6].photoUrl}
                                            alt={data.dishes[6].dishname}
                                        />
                                        <ImageZoom>
                                            <FaSearch />
                                        </ImageZoom>
                                    </DishPhoto>
                                    <DishContent>
                                        <DishName title="dish6">{data.dishes[6].dishname}</DishName>

                                        <DishDescription>
                                            {data.dishes[6].description}
                                        </DishDescription>
                                        <QuantityWrapper>
                                            {' '}
                                            <Price>
                                                ${parseFloat(data.dishes[6].price).toFixed(2)}
                                            </Price>
                                            {qty6 === 0 ? (
                                                <OneButton>
                                                    <Plus>+</Plus>
                                                </OneButton>
                                            ) : (
                                                <BothButtons>
                                                    <Minus>-</Minus>
                                                    <Quantity title="qty6">{qty6}</Quantity>
                                                    <Plus>+</Plus>
                                                </BothButtons>
                                            )}
                                        </QuantityWrapper>
                                    </DishContent>
                                </Dish>
                            </Soldout>
                        )}
                    </>
                ) : (
                    <></>
                )}

                {data.dishes[7] ? (
                    <>
                        {wT7 < cap7 ? (
                            <Dish>
                                <Modal id="modal7">
                                    <div>
                                        <img
                                            src={data.dishes[7].photoUrl}
                                            alt={data.dishes[7].dishname}
                                        />
                                        <button onClick={() => closeModal('7')}>
                                            <FaTimesCircle />
                                        </button>
                                    </div>
                                </Modal>
                                <DishPhoto onClick={() => imageZoom('7')}>
                                    <img
                                        src={data.dishes[7].photoUrl}
                                        alt={data.dishes[7].dishname}
                                    />
                                    <ImageZoom>
                                        <FaSearch />
                                    </ImageZoom>
                                </DishPhoto>
                                <DishContent>
                                    <DishName title="dish7">{data.dishes[7].dishname}</DishName>

                                    <DishDescription>{data.dishes[7].description}</DishDescription>
                                    <QuantityWrapper>
                                        {' '}
                                        <Price>
                                            ${parseFloat(data.dishes[7].price).toFixed(2)}
                                        </Price>
                                        {qty7 === 0 ? (
                                            <OneButton>
                                                <Plus onClick={() => setQty7(qty7 + 1)}>+</Plus>
                                            </OneButton>
                                        ) : (
                                            <BothButtons>
                                                <Minus onClick={() => setQty7(qty7 - 1)}>-</Minus>
                                                <Quantity title="qty7">{qty7}</Quantity>
                                                <Plus onClick={() => setQty7(qty7 + 1)}>+</Plus>
                                            </BothButtons>
                                        )}
                                    </QuantityWrapper>
                                </DishContent>
                            </Dish>
                        ) : (
                            <Soldout>
                                <SoldMessage>Sold Out</SoldMessage>
                                <Dish>
                                    <DishPhoto>
                                        <img
                                            src={data.dishes[7].photoUrl}
                                            alt={data.dishes[7].dishname}
                                        />
                                        <ImageZoom>
                                            <FaSearch />
                                        </ImageZoom>
                                    </DishPhoto>
                                    <DishContent>
                                        <DishName title="dish7">{data.dishes[7].dishname}</DishName>

                                        <DishDescription>
                                            {data.dishes[7].description}
                                        </DishDescription>
                                        <QuantityWrapper>
                                            {' '}
                                            <Price>
                                                ${parseFloat(data.dishes[7].price).toFixed(2)}
                                            </Price>
                                            {qty7 === 0 ? (
                                                <OneButton>
                                                    <Plus>+</Plus>
                                                </OneButton>
                                            ) : (
                                                <BothButtons>
                                                    <Minus>-</Minus>
                                                    <Quantity title="qty7">{qty7}</Quantity>
                                                    <Plus>+</Plus>
                                                </BothButtons>
                                            )}
                                        </QuantityWrapper>
                                    </DishContent>
                                </Dish>
                            </Soldout>
                        )}
                    </>
                ) : (
                    <></>
                )}
                {data.dishes[8] ? (
                    <>
                        {wT8 < cap8 ? (
                            <Dish>
                                <Modal id="modal8">
                                    <div>
                                        <img
                                            src={data.dishes[8].photoUrl}
                                            alt={data.dishes[8].dishname}
                                        />
                                        <button onClick={() => closeModal('8')}>
                                            <FaTimesCircle />
                                        </button>
                                    </div>
                                </Modal>
                                <DishPhoto onClick={() => imageZoom('8')}>
                                    <img
                                        src={data.dishes[8].photoUrl}
                                        alt={data.dishes[8].dishname}
                                    />
                                    <ImageZoom>
                                        <FaSearch />
                                    </ImageZoom>
                                </DishPhoto>
                                <DishContent>
                                    <DishName title="dish8">{data.dishes[8].dishname}</DishName>

                                    <DishDescription>{data.dishes[8].description}</DishDescription>
                                    <QuantityWrapper>
                                        {' '}
                                        <Price>
                                            ${parseFloat(data.dishes[8].price).toFixed(2)}
                                        </Price>
                                        {qty8 === 0 ? (
                                            <OneButton>
                                                <Plus onClick={() => setQty8(qty8 + 1)}>+</Plus>
                                            </OneButton>
                                        ) : (
                                            <BothButtons>
                                                <Minus onClick={() => setQty8(qty8 - 1)}>-</Minus>
                                                <Quantity title="qty8">{qty8}</Quantity>
                                                <Plus onClick={() => setQty8(qty8 + 1)}>+</Plus>
                                            </BothButtons>
                                        )}
                                    </QuantityWrapper>
                                </DishContent>
                            </Dish>
                        ) : (
                            <Soldout>
                                <SoldMessage>Sold Out</SoldMessage>
                                <Dish>
                                    <DishPhoto>
                                        <img
                                            src={data.dishes[8].photoUrl}
                                            alt={data.dishes[8].dishname}
                                        />
                                        <ImageZoom>
                                            <FaSearch />
                                        </ImageZoom>
                                    </DishPhoto>
                                    <DishContent>
                                        <DishName title="dish8">{data.dishes[8].dishname}</DishName>

                                        <DishDescription>
                                            {data.dishes[8].description}
                                        </DishDescription>
                                        <QuantityWrapper>
                                            {' '}
                                            <Price>
                                                ${parseFloat(data.dishes[8].price).toFixed(2)}
                                            </Price>
                                            {qty8 === 0 ? (
                                                <OneButton>
                                                    <Plus>+</Plus>
                                                </OneButton>
                                            ) : (
                                                <BothButtons>
                                                    <Minus>-</Minus>
                                                    <Quantity title="qty8">{qty8}</Quantity>
                                                    <Plus>+</Plus>
                                                </BothButtons>
                                            )}
                                        </QuantityWrapper>
                                    </DishContent>
                                </Dish>
                            </Soldout>
                        )}
                    </>
                ) : (
                    <></>
                )}
                {data.dishes[9] ? (
                    <>
                        {wT9 < cap9 ? (
                            <Dish>
                                <Modal id="modal9">
                                    <div>
                                        <img
                                            src={data.dishes[9].photoUrl}
                                            alt={data.dishes[9].dishname}
                                        />
                                        <button onClick={() => closeModal('9')}>
                                            <FaTimesCircle />
                                        </button>
                                    </div>
                                </Modal>
                                <DishPhoto onClick={() => imageZoom('9')}>
                                    <img
                                        src={data.dishes[9].photoUrl}
                                        alt={data.dishes[9].dishname}
                                    />
                                    <ImageZoom>
                                        <FaSearch />
                                    </ImageZoom>
                                </DishPhoto>
                                <DishContent>
                                    <DishName title="dish9">{data.dishes[9].dishname}</DishName>

                                    <DishDescription>{data.dishes[9].description}</DishDescription>
                                    <QuantityWrapper>
                                        {' '}
                                        <Price>
                                            ${parseFloat(data.dishes[9].price).toFixed(2)}
                                        </Price>
                                        {qty9 === 0 ? (
                                            <OneButton>
                                                <Plus onClick={() => setQty9(qty9 + 1)}>+</Plus>
                                            </OneButton>
                                        ) : (
                                            <BothButtons>
                                                <Minus onClick={() => setQty9(qty9 - 1)}>-</Minus>
                                                <Quantity title="qty9">{qty9}</Quantity>
                                                <Plus onClick={() => setQty9(qty9 + 1)}>+</Plus>
                                            </BothButtons>
                                        )}
                                    </QuantityWrapper>
                                </DishContent>
                            </Dish>
                        ) : (
                            <Soldout>
                                <SoldMessage>Sold Out</SoldMessage>
                                <Dish>
                                    <DishPhoto>
                                        <img
                                            src={data.dishes[9].photoUrl}
                                            alt={data.dishes[9].dishname}
                                        />
                                        <ImageZoom>
                                            <FaSearch />
                                        </ImageZoom>
                                    </DishPhoto>
                                    <DishContent>
                                        <DishName title="dish9">{data.dishes[9].dishname}</DishName>

                                        <DishDescription>
                                            {data.dishes[9].description}
                                        </DishDescription>
                                        <QuantityWrapper>
                                            {' '}
                                            <Price>
                                                ${parseFloat(data.dishes[9].price).toFixed(2)}
                                            </Price>
                                            {qty9 === 0 ? (
                                                <OneButton>
                                                    <Plus>+</Plus>
                                                </OneButton>
                                            ) : (
                                                <BothButtons>
                                                    <Minus>-</Minus>
                                                    <Quantity title="qty9">{qty9}</Quantity>
                                                    <Plus>+</Plus>
                                                </BothButtons>
                                            )}
                                        </QuantityWrapper>
                                    </DishContent>
                                </Dish>
                            </Soldout>
                        )}
                    </>
                ) : (
                    <></>
                )}
                {data.dishes[10] ? (
                    <>
                        {wT10 < cap10 ? (
                            <Dish>
                                <Modal id="modal10">
                                    <div>
                                        <img
                                            src={data.dishes[10].photoUrl}
                                            alt={data.dishes[10].dishname}
                                        />
                                        <button onClick={() => closeModal('10')}>
                                            <FaTimesCircle />
                                        </button>
                                    </div>
                                </Modal>
                                <DishPhoto onClick={() => imageZoom('10')}>
                                    <img
                                        src={data.dishes[10].photoUrl}
                                        alt={data.dishes[10].dishname}
                                    />
                                    <ImageZoom>
                                        <FaSearch />
                                    </ImageZoom>
                                </DishPhoto>
                                <DishContent>
                                    <DishName title="dish10">{data.dishes[10].dishname}</DishName>

                                    <DishDescription>{data.dishes[10].description}</DishDescription>
                                    <QuantityWrapper>
                                        {' '}
                                        <Price>
                                            ${parseFloat(data.dishes[10].price).toFixed(2)}
                                        </Price>
                                        {qty10 === 0 ? (
                                            <OneButton>
                                                <Plus onClick={() => setQty10(qty10 + 1)}>+</Plus>
                                            </OneButton>
                                        ) : (
                                            <BothButtons>
                                                <Minus onClick={() => setQty10(qty10 - 1)}>-</Minus>
                                                <Quantity title="qty10">{qty10}</Quantity>
                                                <Plus onClick={() => setQty10(qty10 + 1)}>+</Plus>
                                            </BothButtons>
                                        )}
                                    </QuantityWrapper>
                                </DishContent>
                            </Dish>
                        ) : (
                            <Soldout>
                                <SoldMessage>Sold Out</SoldMessage>
                                <Dish>
                                    <DishPhoto>
                                        <img
                                            src={data.dishes[10].photoUrl}
                                            alt={data.dishes[10].dishname}
                                        />
                                        <ImageZoom>
                                            <FaSearch />
                                        </ImageZoom>
                                    </DishPhoto>
                                    <DishContent>
                                        <DishName title="dish10">
                                            {data.dishes[10].dishname}
                                        </DishName>

                                        <DishDescription>
                                            {data.dishes[10].description}
                                        </DishDescription>
                                        <QuantityWrapper>
                                            {' '}
                                            <Price>
                                                ${parseFloat(data.dishes[10].price).toFixed(2)}
                                            </Price>
                                            {qty10 === 0 ? (
                                                <OneButton>
                                                    <Plus>+</Plus>
                                                </OneButton>
                                            ) : (
                                                <BothButtons>
                                                    <Minus>-</Minus>
                                                    <Quantity title="qty10">{qty10}</Quantity>
                                                    <Plus>+</Plus>
                                                </BothButtons>
                                            )}
                                        </QuantityWrapper>
                                    </DishContent>
                                </Dish>
                            </Soldout>
                        )}
                    </>
                ) : (
                    <></>
                )}

                <Subhead>
                    Step 2: <span>Confirm pickup</span>
                </Subhead>
                <Pickup>
                    <input
                        name="pickupIsCool"
                        type="checkbox"
                        checked={pickupIsCool}
                        onChange={(e) => setPickupIsCool(!pickupIsCool)}
                    />
                    <label htmlFor="pickupIsCool">
                        I acknowledge that I must provide my preferred pickup date (must be at least
                        48 hours after order time) in the comments section below. I know that pickup
                        will be located in Blue Horizons near the intersection of Jackrabbit Trail
                        and Van Buren in Buckeye, AZ
                    </label>
                </Pickup>

                <Subhead>
                    Step 3: <span>Provide info</span>
                </Subhead>
                <User>
                    <input
                        name="email"
                        placeholder="Email address"
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <input
                        name="name"
                        placeholder="Name"
                        onChange={(e) => setName(e.target.value)}
                    />
                    <input
                        name="phone"
                        placeholder="Phone or Instagram handle"
                        onChange={(e) => setPhone(e.target.value)}
                    />
                    <textarea
                        name="comment"
                        rows="6"
                        placeholder="Enter comment or pickup concerns"
                        onChange={(e) => setComment(e.target.value)}
                    />
                </User>
            </ContentWrapper>

            <BottomBar>
                <CurrentTotal>
                    <strong>Total: </strong>${parseFloat(checkoutTotal).toFixed(2)}
                </CurrentTotal>
                {email && name && totalItems && pickupIsCool ? (
                    <CheckoutButton>
                        <button onClick={handleSubmit}>Submit order</button>
                    </CheckoutButton>
                ) : (
                    <NoCheckoutButton>
                        <button>
                            <del>Submit order</del>
                        </button>
                    </NoCheckoutButton>
                )}
            </BottomBar>
        </>
    );
}

const Soldout = styled.div`
    background-color: rgba(0, 0, 0, 0.7);
    position: relative;
    div,
    div span {
        opacity: 0.6;
    }
    img,
    svg {
        opacity: 0.3;
    }
    div button {
        opacity: 0.3;
    }
`;

const SoldMessage = styled.span`
    align-items: center;
    color: rgba(255, 255, 255, 1);
    display: block;
    display: flex;
    font-size: 28px;
    font-weight: 700;
    height: 100%;
    justify-content: center;
    padding: 50px;
    position: absolute;
    width: 100%;
    z-index: 1;
`;

const ContentWrapper = styled.div`
    position: relative;
    margin: 0 auto;
    max-width: 900px;
    width: 100%;
`;

const TopBar = styled.div`
    background: var(--red);
    color: white;
    font-size: 17px;
    font-weight: 800;
    letter-spacing: 2px;
    padding: 15px;
    position: fixed;
    text-align: left;
    text-transform: uppercase;
    width: 100vw;
    z-index: 2;
`;

const Modal = styled.div`
    background-color: rgba(0, 0, 0, 0.9);
    display: none;
    height: 100vh;
    left: 0;
    overflow: hidden;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 3;
    div {
        align-items: center;
        display: flex;
        height: 100%;
        justify-content: center;
        padding: 0px;
    }
    img {
        color: white;
        display: block;
        font-size: 16px;
        height: 100%;
        max-height: 600px;
        overflow: auto;
        padding: 20px;
        position: relative;
        width: auto;
        @media screen and (max-width: 899px) {
            height: auto;
            max-width: 100%;
        }
    }
    p {
        color: white;
        display: block;
        font-size: 16px;
        height: 50%;
        overflow: auto;
        padding: 20px;
        position: relative;
    }
    button {
        background-color: transparent;
        border: 0;
        color: white;
        font-size: 45px;
        position: absolute;
        right: 30px;
        top: 30px;
        z-index: 4;
    }
`;

const MealPlanPromo = styled.img`
    position: absolute;
    width: 200px;
    margin: 60px 10px 0 0;
    right: 0;
    @media screen and (min-width: 500px) {
        margin: 60px 60px 0 0;
    }
    &:hover {
        opacity: 0.7;
    }
`;

const Subhead = styled.h2`
    font-size: 26px;
    font-weight: 700;
    padding: 40px 20px 20px 20px;
    &:first-of-type {
        padding-top: 180px;
        @media screen and (min-width: 500px) {
            padding-top: 120px;
        }
    }
    span {
        font-weight: 500;
    }
`;

const Title = styled.div`
    display: inline-block;
    padding-left: 20px;
    @media screen and (max-width: 899px) {
        padding-left: 10px;
    }
`;

const Cart = styled.span`
    font-size: 20px;
    position: absolute;
    right: 32px;
    @media screen and (max-width: 899px) {
        right: 34px;
    }
`;

const TotalDot = styled.span`
    background-color: red;
    border-radius: 50%;
    color: white;
    font-family: Helvetica, Arial, sans-serif;
    font-size: 12px;
    font-weight: 700;
    height: 20px;
    letter-spacing: -1px;
    padding: 3px 2px 0 2px;
    position: absolute;
    right: 20px;
    text-align: center;
    top: -3px;
    width: 20px;
    z-index: 2;
    @media screen and (max-width: 899px) {
        right: 20px;
    }
}
`;

const Dish = styled.div`
    border-bottom: dashed 1px rgba(255, 255, 255, 0.4);
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 90px 1fr;
    margin: 0 10px;
    padding: 20px 10px;
    z-index: 0;
`;

const DishPhoto = styled.div`
    position: relative;
    img {
        border-radius: 9px;
        height: 120px;
        object-fit: cover;
        width: 90px;
    }
`;

const ImageZoom = styled.span`
    background-color: black;
    border-radius: 50%;
    color: white;
    font-size: 12px;
    height: 27px;
    padding: 8px;
    position: absolute;
    right: 10px;
    top: 10px;
    width: 27px;
    svg {
        text-shadow: 6px 6px 7px rgba(0, 0, 0, 0.4);
    }
`;

const DishContent = styled.div`
    position: relative;
`;

const DishName = styled.div`
    font-size: 19px;
    font-weight: 700;
    padding-top: 5px;
`;

const QuantityWrapper = styled.div`
    bottom: 0;
    position: absolute;
    width: 100%;
`;

const DishDescription = styled.p`
    font-size: 15px;
    font-weight: 600;
    padding: 5px 0 60px 0;
`;

const Price = styled.span`
    display: inline-block;
    float: left;
    font-weight: 700;
`;

const OneButton = styled.span`
    display: inline-block;
    float: right;
`;
const BothButtons = styled.span`
    display: inline-block;
    float: right;
`;

const Minus = styled.button`
    border-bottom-left-radius: 20px;
    border-top-left-radius: 20px;
    border: 1px solid white;
    font-size: 16px;
    font-weight: 800;
    padding: 10px 20px;
`;

const Plus = styled.button`
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
    border: 1px solid white;
    font-size: 16px;
    font-weight: 800;
    padding: 10px 20px;
`;

const Quantity = styled.span`
    font-weight: 700;
    padding: 10px;
`;

const Pickup = styled.div`
    font-size: 15px;
    line-height: 17px;
    font-weight: 600;
    padding: 0px 20px 20px 20px;
    input {
        color: white;
        display: block;
        float: left;
        width: 35px;
        height: 35px;
        margin: 8px 0 35px 0;
    }
    label {
        position: relative;
        left: 10px;
        max-width: 600px;
        top: 14px;
    }
    @media screen and (max-width: 500px) {
        input {
            margin: 8px 0 80px 0;
        }
    }
`;

const User = styled.div`
    padding: 0px 20px 80px 20px;

    input {
        display: block;
        margin-top: 10px;
        padding: 15px;
        width: 100%;
        &:first-of-type {
            margin-top: 0;
        }
    }
    textarea {
        display: block;
        font-family: Arial, Helvetica, sans-serif;
        margin-top: 10px;
        padding: 15px;
        width: 100%;
    }
`;

const BottomBar = styled.div`
    box-shadow: 0 -1px 13px rgba(0, 0, 0, 0.6);
    bottom: 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: 50px;
    position: fixed;
    width: 100vw;
    z-index: 2;
`;
const CurrentTotal = styled.div`
    background-color: white;
    color: var(--red);
    font-weight: 800;
    padding: 15px;
    text-align: center;
    strong {
        color: #555;
    }
`;
const CheckoutButton = styled.div`
    background-color: var(--green);
    text-align: center;
    button {
        background-color: transparent;
        border: 0;
        color: white;
        font-size: 15px;
        font-weight: 700;
        width: 100%;
        height: 100%;
        padding: 15px;
    }
`;

const NoCheckoutButton = styled.div`
    background-color: var(--green);

    text-align: center;
    button {
        background-color: transparent;
        border: 0;
        color: #3fb7c4;
        font-size: 15px;
        font-weight: 700;
        width: 100%;
        height: 100%;
        padding: 15px;
    }
`;
