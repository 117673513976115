import React from 'react';
import ReactDOM from 'react-dom';

import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { createHttpLink } from 'apollo-link-http';
import { ApolloProvider } from '@apollo/react-hooks';
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import App from './App';
// import ScrollToTop from './components/ScrollToTop';
import { GlobalStyle } from './DefaultStyles';
import { db } from './Api';

const httpLink = createHttpLink({
    uri: db,
});

const client = new ApolloClient({
    link: httpLink,
    cache: new InMemoryCache(),
});

const ApolloApp = () => (
    <ApolloProvider client={client}>
        <GlobalStyle />
        <BrowserRouter>
            {/* <ScrollToTop> */}
            <App db={db} />

            {/* </ScrollToTop> */}
        </BrowserRouter>
    </ApolloProvider>
);

ReactDOM.render(<ApolloApp />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
