import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FaHome } from 'react-icons/fa';

export default function Virtual() {
    return (
        <Wrapper>
            <TopBar>
                <ContentWrapper>
                    <Link to="/">
                        <FaHome />
                    </Link>
                    <Title>Em's meals to go</Title>
                </ContentWrapper>
            </TopBar>
            <ContentWrapper>
                <InnerWrapper>
                    <img src="virtual-lede.jpg" alt="virtual consultation" />

                    <p>
                        Looking for something a bit more personal? Request a 30 minute consultation
                        and get yourself on the right path!
                    </p>
                    <ul>
                        <li>
                            A 30-minute, personal virtual consultation via Zoom (link will be sent
                            within 12 hours of scheduling)
                        </li>
                        <li>
                            Consultation cost is only a $20 (refundable) fee. This can be sent via
                            venmo (@emilypliske) or zelle/quickpay (epliske@gmail.com) and must be
                            sent 24 hours before scheduled appointment time.
                        </li>
                        <li>We'll go over the meal plan form in detail together</li>
                        <li>Questions are strongly encouraged</li>
                        <li>
                            Receive an email afterwards summarizing our video call and what steps
                            are next
                        </li>
                    </ul>

                    <form name="virtual" method="post">
                        <input type="hidden" name="form-name" value="virtual" required />
                        <p>
                            <label>
                                <strong>Name</strong>: <Input type="text" name="name" required />
                            </label>
                        </p>
                        <p>
                            <label>
                                <strong>Email</strong>: <Input type="text" name="email" required />
                            </label>
                        </p>
                        <p>
                            <button type="submit">Submit</button>
                        </p>
                    </form>
                </InnerWrapper>
            </ContentWrapper>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    background-image: linear-gradient(135deg, #ebddd3 0%, #dbc9ba 100%);
`;

const TopBar = styled.div`
    background: var(--red);
    color: white;
    font-size: 17px;
    font-weight: 800;
    letter-spacing: 2px;
    padding: 15px;
    position: fixed;
    text-align: left;
    text-transform: uppercase;
    width: 100vw;
    z-index: 2;
`;

const Title = styled.div`
    display: inline-block;
    padding-left: 20px;
    @media screen and (max-width: 899px) {
        padding-left: 10px;
    }
`;

const ContentWrapper = styled.div`
    margin: 0 auto;
    max-width: 900px;
    position: relative;
    width: 100%;
    a {
        background-color: black;
        padding: 7px 7px 2px 7px;
        border-radius: 6px;
        position: relative;
        top: 2px;
        svg {
            color: white;
        }
        &:hover {
            opacity: 0.75;
            svg {
                color: white;
            }
        }
    }
`;

const InnerWrapper = styled.div`
    padding: 50px 0px 20px 0px;
    width: 100%;
    @media screen and (min-width: 500px) {
        padding: 80px 20px 20px 20px;
    }
    h1 {
        margin-bottom: 15px;
    }

    label {
        color: #333;
        font-style: italic;
        font-weight: 500;
        margin-right: 15px;
        font-size: 14px;
    }

    strong {
    }

    p {
        font-style: italic;
        font-weight: 600;
        padding: 10px 20px;
        font-size: 16px;
    }
    ul {
        margin: 20px 30px;
    }

    li {
        margin: 15px;
        font-weight: 500;
        font-size: 15px;
    }
    img {
        width: 100%;
        max-width: 900px;
    }
    button {
        background-color: var(--red);
        border: 0;
        color: white;
        font-family: 'raleway', sans-serif;
        font-size: 17px;
        font-weight: 700;
        outline: none;
        padding: 15px 30px;
        &:hover {
            cursor: pointer;
            opacity: 0.7;
        }
    }
`;

const Input = styled.input`
    padding: 10px;
    font-size: 15px;
    width: ${(props) => (props.narrow ? '70px' : 'auto')};
    margin: ${(props) => (props.radiospace ? '0 0 0 4px' : 'auto')};
`;
