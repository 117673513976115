/* eslint-disable react/destructuring-assignment */
import React from 'react';
import styled from 'styled-components';
import { useQuery } from '@apollo/react-hooks';
// local imports
import { GET_LIVE } from '../graphql/getLive';
import Dishes from '../components/Dishes';

function Home({ ...props }) {
    const { data, error, loading } = useQuery(GET_LIVE, {});
    if (loading) return <h2>Loading ....</h2>;
    if (error) return `Error! ${error}`;

    // console.log('====================================');
    // console.log(data);
    // console.log('====================================');

    return (
        <>
            {data.lives[0].status === true ? (
                <Wrapper>
                    <Dishes />
                </Wrapper>
            ) : (
                <HiddenSite>
                    <Screen>
                        <Headline>Oh no! Orders are closed.</Headline>
                        <Deck>
                            {' '}
                            I’m busy in the kitchen. Check back in with me on Tuesday for a new
                            menu!
                        </Deck>
                        <a href="https://www.instagram.com/ems.bitchin.kitchen/" target="new">
                            <Instagram>
                                <img src="/instagram.jpg" alt="instagram" />
                                <p>
                                    Stay updated with me on <span>Instagram</span>!
                                </p>
                            </Instagram>
                        </a>
                    </Screen>
                </HiddenSite>
            )}
        </>
    );
}

export default Home;

const Wrapper = styled.div`
    width: 100%;
`;

const Screen = styled.div`
    position: absolute;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.6);
    margin: 0 auto;
    padding-top: 20%;
    @media only screen and (min-width: 501px) {
        padding-top: 24%;
    }
    @media only screen and (min-width: 701px) {
        padding-top: 20%;
    }
    @media only screen and (min-width: 901px) {
        padding-top: 15%;
    }
    @media only screen and (min-width: 1201px) {
        padding-top: 15%;
    }
    @media only screen and (min-width: 1501px) {
        padding-top: 10%;
    }
`;

const HiddenSite = styled.div`
    position: absolute;
    width: 100%;
    height: 100vh;

    @media only screen and (min-width: 501px) {
        background: url('https://res.cloudinary.com/billpliske/image/upload/v1593906220/emily/dinner-vertical.jpg');
        background-attachment: fixed;
        background-size: cover;
        background-position: center;
    }
    background: url('https://res.cloudinary.com/billpliske/image/upload/v1593906220/emily/dinner-vertical.jpg');
    background-attachment: fixed;
    background-size: cover;
    background-position: center;
`;

const Headline = styled.h1`
    border-radius: 10px;
    color: #ffa694;
    font-size: 34px;
    font-weight: 800;
    line-height: 34px;
    margin: 3vh auto 0 auto;
    max-width: 80vw;
    padding: 10px;
    text-align: center;

    @media only screen and (min-width: 400px) {
        font-size: 37px;
        line-height: 37px;
        padding: 10px;
    }
    @media only screen and (min-width: 700px) {
        font-size: 48px;
        line-height: 50px;
    }
`;

const Deck = styled.h2`
    color: white;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    margin: 0px auto 0 auto;
    max-width: 80vw;
    padding: 0px 10px;
    text-align: center;

    @media only screen and (min-width: 700px) {
        font-size: 24px;
        line-height: 29px;
    }
`;

const Instagram = styled.div`
    color: white;
    font-weight: 600;
    margin: 50px auto 0 auto;
    padding: 10px;
    text-align: center;
    width: 200px;
    span {
        text-decoration: underline;
    }
    img {
        border-radius: 50%;
        margin-bottom: 5px;
        width: 80px;
    }
    &:hover {
        cursor: pointer;
        p {
            color: #ffa694;
        }
        img {
            box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.5);
        }
    }
    @media only screen and (min-width: 700px) {
        img {
            width: 100px;
        }
        font-size: 18px;
        width: 220px;
    }
`;
