import gql from 'graphql-tag';

export const GET_DISHES = gql`
    query getDishes(
        $dishname: String
        $description: String
        $photoUrl: String
        $isAvailable: Boolean
        $price: Float
        $capacity: Int
        $weeklyTotal: Int
    ) {
        dishes(
            dishname: $dishname
            description: $description
            photoUrl: $photoUrl
            isAvailable: $isAvailable
            price: $price
            capacity: $capacity
            weeklyTotal: $weeklyTotal
        ) {
            id
            dishname
            description
            photoUrl
            isAvailable
            price
            capacity
            weeklyTotal
        }
    }
`;
