import { createGlobalStyle } from 'styled-components';
import styled from 'styled-components';

document.documentElement.style.setProperty('--vh', `${window.innerHeight / 100}px`);
function setDocHeight() {
    document.documentElement.style.setProperty('--vh', `${window.innerHeight / 100}px`);
}

window.addEventListener('resize', function () {
    setDocHeight();
});
window.addEventListener('orientationchange', function () {
    setDocHeight();
});

setDocHeight();

export const GlobalStyle = createGlobalStyle`

@font-face {
    font-family: "raleway";
    font-style: normal;
    font-weight: 100;
    src: url(/fonts/raleway-thin-webfont.woff2) format("woff2");
}

@font-face {
    font-family: "raleway";
    font-style: italic;
    font-weight: 100;
    src: url(/fonts/raleway-thinitalic-webfont.woff2) format("woff2");
}

@font-face {
    font-family: "raleway";
    font-style: normal;
    font-weight: 200;
    src: url(/fonts/raleway-extralight-webfont.woff2) format("woff2");
}

@font-face {
    font-family: "raleway";
    font-style: italic;
    font-weight: 200;
    src: url(/fonts/raleway-extralightitalic-webfont.woff2)
        format("woff2");
}

@font-face {
    font-family: "raleway";
    font-style: normal;
    font-weight: 300;
    src: url(/fonts/raleway-light-webfont.woff2) format("woff2");
}

@font-face {
    font-family: "raleway";
    font-style: italic;
    font-weight: 300;
    src: url(/fonts/raleway-lightitalic-webfont.woff2) format("woff2");
}

@font-face {
    font-family: "raleway";
    font-style: normal;
    font-weight: 400;
    src: url(/fonts/raleway-regular-webfont.woff2) format("woff2");
}

@font-face {
    font-family: "raleway";
    font-style: italic;
    font-weight: 400;
    src: url(/fonts/raleway-italic-webfont.woff2) format("woff2");
}

@font-face {
    font-family: "raleway";
    font-style: normal;
    font-weight: 500;
    src: url(/fonts/raleway-semibold-webfont.woff2) format("woff2");
}

@font-face {
    font-family: "raleway";
    font-style: italic;
    font-weight: 500;
    src: url(/fonts/raleway-semibolditalic-webfont.woff2) format("woff2");
}

@font-face {
    font-family: "raleway";
    font-style: normal;
    font-weight: 600;
    src: url(/fonts/raleway-bold-webfont.woff2) format("woff2");
}

@font-face {
    font-family: "raleway";
    font-style: italic;
    font-weight: 600;
    src: url(/fonts/raleway-bolditalic-webfont.woff2) format("woff2");
}

@font-face {
    font-family: "raleway";
    font-style: normal;
    font-weight: 700;
    src: url(/fonts/raleway-extrabold-webfont.woff2) format("woff2");
}

@font-face {
    font-family: "raleway";
    font-style: italic;
    font-weight: 700;
    src: url(/fonts/raleway-extrabolditalic-webfont.woff2) format("woff2");
}

@font-face {
    font-family: "raleway";
    font-style: normal;
    font-weight: 800;
    src: url(/fonts/raleway-black-webfont.woff2) format("woff2");
}

@font-face {
    font-family: "raleway";
    font-style: italic;
    font-weight: 800;
    src: url(/fonts/raleway-blackitalic-webfont.woff2) format("woff2");
}

@font-face {
    font-family: "open_sanslight";
    font-style: normal;
    font-weight: normal;
    src: url(/fonts/OpenSans-Light-webfont.woff) format("woff");
}




html, body {
    background: #c6ffdd;
    font-family: "raleway", sans-serif;
    height: 100%;
    height: 100vh;
    justify-content: stretch;
    background-image: linear-gradient(135deg,#ebddd3 0%,#dbc9ba 100%);

}

body > #root {
    height: 100%;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}



.development .devlabel {
    display: block;
}


.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-in;
}

:root {
    /**** MAIN ****/
    --very-dark-blue: #201E2E;
    --cornflower: #6D76FF;

    /**** COLORS ****/

    --green: #136972;
    --red: #136972;

    /**** SIZES ****/
    --padding-outer: 20px;
    --text: var(--very-dark-gray);
    --text-header: 44px;
    --text-secondary: var(--dark-gray);
    --text-link: var(--cornflower);
    --text-label: var(--cornflower);
    --text-light: var(--white);
`;

export const AmountOverlayButton = styled.div`
    background-color: rgba(5, 5, 5, 0.1);
    display: flex;
    padding: 40px 25px;
    height: 200px;
    position: absolute;
    width: 100%;
    text-align: right;
    z-index: 10;
    input {
        background-color: #2b2c38;
        border: none;
        color: transparent;
        cursor: none;
        font-family: 'open_sanslight', sans-serif;
        font-size: 70px;
        letter-spacing: -2px;
        text-align: right;
        text-shadow: 0 0 0 var(--red);
        &:focus {
            outline: none;
        }
    }
`;

export const AmountOverlayButtonIncome = styled.div`
    background-color: rgba(5, 5, 5, 0.1);
    display: flex;
    padding: 40px 25px;
    height: 200px;
    position: absolute;
    width: 100%;
    text-align: right;
    z-index: 10;
    input {
        background-color: #2b2c38;
        border: none;
        color: transparent;
        cursor: none;
        font-family: 'open_sanslight', sans-serif;
        font-size: 70px;
        letter-spacing: -2px;
        text-align: right;
        text-shadow: 0 0 0 var(--green);
        &:focus {
            outline: none;
        }
    }
`;
