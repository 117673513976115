import gql from 'graphql-tag';

export const CREATE_ORDER = gql`
    mutation createOrder(
        $email: String!
        $name: String!
        $phone: String!
        $comment: String
        $totalItems: Int
        $checkoutTotal: Float
        $hasPaid: Boolean
        $date: Date
        $dish0: String
        $qty0: Int
        $dish1: String
        $qty1: Int
        $dish2: String
        $qty2: Int
        $dish3: String
        $qty3: Int
        $dish4: String
        $qty4: Int
        $dish5: String
        $qty5: Int
        $dish6: String
        $qty6: Int
        $dish7: String
        $qty7: Int
        $dish8: String
        $qty8: Int
        $dish9: String
        $qty9: Int
        $dish10: String
        $qty10: Int
    ) {
        createOrder(
            # this entryInput is correct
            orderInput: {
                email: $email
                name: $name
                phone: $phone
                comment: $comment
                totalItems: $totalItems
                checkoutTotal: $checkoutTotal
                hasPaid: $hasPaid
                date: $date
                dish0: $dish0
                qty0: $qty0
                dish1: $dish1
                qty1: $qty1
                dish2: $dish2
                qty2: $qty2
                dish3: $dish3
                qty3: $qty3
                dish4: $dish4
                qty4: $qty4
                dish5: $dish5
                qty5: $qty5
                dish6: $dish6
                qty6: $qty6
                dish7: $dish7
                qty7: $qty7
                dish8: $dish8
                qty8: $qty8
                dish9: $dish9
                qty9: $qty9
                dish10: $dish10
                qty10: $qty10
            }
        ) {
            email
            name
            phone
            totalItems
            checkoutTotal
            hasPaid
            date
            dish0
            qty0
            dish1
            qty1
            dish2
            qty2
            dish3
            qty3
            dish4
            qty4
            dish5
            qty5
            dish6
            qty6
            dish7
            qty7
            dish8
            qty8
            dish9
            qty9
            dish10
            qty10
        }
    }
`;
