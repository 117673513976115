/* eslint-disable import/no-named-as-default */
// import libraries

import React from 'react';
import styled from 'styled-components';
import { Route, Switch } from 'react-router-dom';
import Home from './Home';
import Thanks from './Thanks';
import MealPlanForm from './MealPlanForm';
import Virtual from './Virtual';
// import Pickup from './Pickup';

// EXAMPLE: http://localhost:3000/pickup/47462540058

const Routing = (props) => (
    <Wrapper>
        <Switch>
            <Route exact path="/" component={Home} />
            <Route
                exact
                path="/home"
                render={(routeProps) => <Home {...routeProps} {...props} />}
            />
            <Route
                exact
                path="/thanks"
                render={(routeProps) => <Thanks {...routeProps} {...props} />}
            />
            <Route exact path="/home" component={MealPlanForm} />
            <Route exact path="/mealplan-form" component={MealPlanForm} />
            <Route exact path="/virtual-form" component={Virtual} />
        </Switch>
    </Wrapper>
);

// define your styles
const Wrapper = styled.div`
    position: relative;
`;

export default Routing;
