import React from 'react';
import styled from 'styled-components';

// LOCAL IMPORTS

import Routing from './pages/Routing';

function App() {
    return (
        <Wrapper>
            <Routing />
        </Wrapper>
    );
}

// define your styles
const Wrapper = styled.div`
    height: 100%;
    margin: 0 auto;
    min-height: 100%;
    position: relative;
    width: 100%;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
`;

export default App;
