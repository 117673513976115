import React from 'react';
import styled from 'styled-components';

export default function Thanks(props) {
    return (
        <Wrapper>
            <Headline>Thanks!</Headline>
            <Chatter>
                Please send your payment of
                <strong> ${parseFloat(props.location.state.checkoutTotal).toFixed(2)} </strong> in
                either of the following ways:
            </Chatter>
            <LogoContainer>
                <img src="/venmo-logo.png" alt="Venmno" />
                <LogoText>Use "emilypliske"</LogoText>
                <img src="/zelle-logo.png" alt="Zelle" />
                <LogoText>Use "epliske@gmail.com"</LogoText>
            </LogoContainer>
            {/*
            <p>{props.location.state.checkoutTotal}</p> */}
        </Wrapper>
    );
}

const Wrapper = styled.div`
    background: #c6ffdd; /* fallback for old browsers */
    background: -webkit-linear-gradient(
        to bottom,
        #f7797d,
        #fbd786,
        #c6ffdd
    ); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(
        to bottom,
        #f7797d,
        #fbd786,
        #c6ffdd
    ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    height: 100vh;
    padding: 20px;
`;

const Headline = styled.h1`
    color: white;
    font-size: 50px;
    padding-top: 40px;
`;

const LogoContainer = styled.div`
    width: 60%;
    img {
        display: block;
        margin: 15px auto 0 auto;
        width: 100%;
        max-width: 500px;
    }
`;

const LogoText = styled.p`
    font-style: italic;
    font-weight: 700;
    padding: 5px 0 30px 0;
`;

const Chatter = styled.p`
    color: #333;
    font-size: 18px;
    font-weight: 600;
    padding-bottom: 10px;
    strong {
        color: black;
        display: block;
        font-size: 26px;
        font-weight: 800;
    }
`;
