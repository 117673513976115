import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FaHome } from 'react-icons/fa';
import { FiMonitor } from 'react-icons/fi';

export default function MealPlanForm() {
    return (
        <Wrapper>
            <TopBar>
                <ContentWrapper>
                    <Link to="/">
                        <FaHome />
                    </Link>
                    <Title>Em's meals to go</Title>
                </ContentWrapper>
            </TopBar>
            <ContentWrapper>
                <InnerWrapper>
                    <Virtual>
                        <Link to="/virtual-form">
                            <FiMonitor />
                            Would you prefer a personal <span>virtual consultation?</span>
                        </Link>
                    </Virtual>
                    <h1>Meal Plan form</h1>

                    <form name="mealplan" method="post">
                        <input type="hidden" name="form-name" value="mealplan" required />
                        <p>
                            <label>
                                <strong>Name</strong>: <Input type="text" name="name" required />
                            </label>
                        </p>
                        <p>
                            <label>
                                <strong>Email</strong>: <Input type="email" name="email" required />
                            </label>
                        </p>
                        <p>
                            <label>
                                <strong>Age</strong>:{' '}
                                <Input narrow type="number" name="age" maxlength="3" required />
                            </label>
                            <label>
                                <strong>Sex</strong>:{' '}
                                <select name="sex" required>
                                    <option value="" disabled selected hidden>
                                        Select ...
                                    </option>
                                    <option value="male">Male</option>
                                    <option value="female">Female</option>
                                    <option value="rather not answer">Skip this</option>
                                </select>
                            </label>
                        </p>
                        <p>
                            <label>
                                <strong>Height</strong>:{' '}
                                <Input narrow type="text" name="height" required />
                            </label>
                            <label>
                                <strong>Weight</strong>:{' '}
                                <Input narrow name="weight" type="text" maxlength="3" required />
                            </label>
                        </p>
                        <p>
                            <label>
                                <strong>Food allergies?</strong>
                            </label>
                            <p>
                                <label>
                                    Yes
                                    <Input
                                        radiospace
                                        type="radio"
                                        name="allergies"
                                        value="yes"
                                        required
                                    />
                                </label>
                                <label>
                                    No
                                    <Input radiospace type="radio" name="allergies" value="no" />
                                </label>
                            </p>
                            <p>
                                If allergies answer was "yes", list allergies below"
                                <label>
                                    <textarea name="allergy list" rows="5"></textarea>
                                </label>
                            </p>
                        </p>
                        <p>
                            <label>
                                <strong>Family history</strong>:{' '}
                                <textarea
                                    name="family history"
                                    rows="5"
                                    placeholder="Any relevant family history? List it here!"
                                    required
                                ></textarea>
                            </label>
                        </p>
                        <p>
                            <label>
                                <strong>Food likes</strong>:{' '}
                                <textarea
                                    name="food likes"
                                    rows="5"
                                    placeholder="Foods you like, separated by commas"
                                    required
                                ></textarea>
                            </label>
                        </p>
                        <p>
                            <label>
                                <strong>Food dislikes</strong>:{' '}
                                <textarea
                                    name="food dislikes"
                                    rows="5"
                                    placeholder="Foods you dislike, separated by commas"
                                    required
                                ></textarea>
                            </label>
                        </p>
                        <p>
                            <label>
                                <strong>Cultural food preferences</strong> (if applicable):{' '}
                                <textarea
                                    name="cultural"
                                    rows="5"
                                    placeholder="List any preferences here"
                                    required
                                ></textarea>
                            </label>
                        </p>
                        <p>
                            <label>
                                <strong>Food restrictions (if applicable)</strong>:{' '}
                                <textarea
                                    name="restrictions"
                                    rows="5"
                                    placeholder="List any food restrictions"
                                    required
                                ></textarea>
                            </label>
                        </p>
                        <p>
                            <label>
                                <strong>What are your health goals?</strong>:{' '}
                                <textarea
                                    name="goals"
                                    rows="5"
                                    placeholder="Let me hear about it!"
                                    required
                                ></textarea>
                            </label>
                        </p>
                        <p>
                            <label>
                                <strong>What can you tell me about your lifestyle schedule?</strong>
                                :{' '}
                                <textarea
                                    name="lifestyle"
                                    rows="5"
                                    placeholder="What's a typical week like for you?"
                                    required
                                ></textarea>
                            </label>
                        </p>
                        <p>
                            <label>
                                <strong>Bowel regularity</strong>:{' '}
                                <textarea
                                    name="bowel"
                                    rows="3"
                                    placeholder="Yeah, I'm actually asking."
                                    required
                                ></textarea>
                            </label>
                        </p>
                        <p>
                            <label>
                                <strong>Physical activity</strong>:{' '}
                                <select name="physical" required>
                                    <option value="" disabled selected hidden>
                                        Select ...
                                    </option>
                                    <option value="sedentary">Sedentary</option>
                                    <option value="light">Light</option>
                                    <option value="moderate">Moderate</option>
                                    <option value="vigorous">Vigorous</option>
                                </select>
                            </label>
                        </p>
                        <p>
                            <label>
                                <strong>Health status</strong> (i.e. diabetes, celiac, crohns):{' '}
                                <textarea
                                    name="health status"
                                    rows="5"
                                    placeholder="Provide health status info"
                                    required
                                ></textarea>
                            </label>
                        </p>

                        <p>
                            <button type="submit">Send</button>
                        </p>
                    </form>
                </InnerWrapper>
            </ContentWrapper>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    position: relative;
`;

const TopBar = styled.div`
    background: var(--red);
    color: white;
    font-size: 17px;
    font-weight: 800;
    letter-spacing: 2px;
    padding: 15px;
    position: fixed;
    text-align: left;
    text-transform: uppercase;
    width: 100vw;
    z-index: 2;
    a {
        background-color: black;
        padding: 7px 7px 2px 7px;
        border-radius: 6px;
        position: relative;
        top: 2px;
        svg {
            color: white;
        }
        &:hover {
            opacity: 0.75;
            svg {
                color: white;
            }
        }
    }
`;

const Title = styled.div`
    display: inline-block;
    padding-left: 20px;
    @media screen and (max-width: 899px) {
        padding-left: 10px;
    }
`;

const ContentWrapper = styled.div`
    margin: 0 auto;
    max-width: 900px;
    position: relative;
    width: 100%;
`;

const Virtual = styled.div`
    background-color: #a9d7dc;
    border-radius: 20px;
    border: 2px solid var(--red);
    display: inline-block;
    font-style: italic;
    font-weight: 600;
    margin-bottom: 40px;
    padding: 10px 20px 10px 10px;
    text-decoration: underline;
    a {
        color: var(--red);
    }
    span {
        color: #333;
        font-weight: 900;
    }
    svg {
        width: 40px;
        height: 20px;
        float: left;
    }
    &:hover {
        background-color: #8abbc0;
        cursor: pointer;
    }
`;

const InnerWrapper = styled.div`
    padding: 80px 10px 20px 10px;
    @media screen and (min-width: 500px) {
        padding: 80px 20px 20px 20px;
    }
    h1 {
        margin-bottom: 15px;
    }

    label {
        color: #333;
        font-style: italic;
        font-weight: 500;
        margin-right: 15px;
        font-size: 14px;
    }

    strong {
    }

    p {
        padding: 10px;
        p {
        }
    }
    select {
        padding: 10px;
        font-size: 15px;
    }
    option {
        font-size: 15px;
        padding: 10px;
    }
    textarea {
        display: block;
        font-family: Arial, Helvetica, sans-serif;
        margin-top: 10px;
        padding: 15px;
        width: 100%;
    }
    button {
        background-color: var(--red);
        border: 0;
        color: white;
        font-family: 'raleway', sans-serif;
        font-size: 17px;
        font-weight: 700;
        outline: none;
        padding: 15px 30px;
        &:hover {
            cursor: pointer;
            opacity: 0.7;
        }
    }
`;

const Input = styled.input`
    padding: 10px;
    font-size: 15px;
    width: ${(props) => (props.narrow ? '70px' : 'auto')};
    margin: ${(props) => (props.radiospace ? '0 0 0 4px' : 'auto')};
`;

// const Label = styled.label`
//     display: ${(props) => (props.inline ? 'inline-block' : 'block')};
//     font-size: 14px;
//     font-style: italic;
//     padding: 15px 0 0 0;
//     margin: ${(props) => (props.inline ? '0 20px 0 0' : '0')};
// `;

// const Input = styled.input`
//     display: block;
//     margin-top: 3px;
//     padding: 15px;
//     width: 100%;
// `;

// const Submit = styled.button`
//     display: block;
//     margin: 30px auto;
//     padding: 10px 19px;
//     border: 0;
//     outline: 0;
// `;
