import gql from 'graphql-tag';

export const UPDATE_WEEKLY_TOTAL = gql`
    mutation UpdateWeeklyTotal($id: ID!, $weeklyTotal: Int) {
        updateWeeklyTotal(id: $id, data: { weeklyTotal: $weeklyTotal }) {
            id
            weeklyTotal
        }
    }
`;
